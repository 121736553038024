@import url('https://fonts.googleapis.com/css?family=ABeeZee:400,700');

@font-face {
  font-family: "minstrels";
  /*Can be any text*/
  src: local("Minstrels"),
    url("./fonts/Minstrels.ttf") format("truetype");
}

@font-face {
  font-family: "typewriter";
  /*Can be any text*/
  src: local("SpecialElite"),
    url("./fonts/SpecialElite.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  font-family: 'typewriter', sans-serif;
  box-sizing: border-box;
}

body {
  height: 100vh;
  background-color: #f4f4f4;
  /* background-image: linear-gradient(to bottom right, #000000 30%, #02fedc 80%); */
  background-attachment: fixed;

  background-repeat: no-repeat;
  background-size: cover;
}

h1,
h2,
p,
li.nav-item,
a:link,
a:visited,
a:active {
  color: #ffffff;
}

a:hover {
  color: #02fedc;
  cursor: pointer;
}

a:link {
  font-size: 1.3rem;
  text-decoration: none;
  margin-right: 20px;
}

.input-box {
  border: 0 !important;
  border-radius: 2rem !important;
  outline-width: 0 !important;
  box-shadow: 1px 1px 8px rgb(212, 212, 212);
}

/* Buttons */

.whitepaper-button {
  width: 57%;
  font-size: 1.2rem;
  border: 0px !important;
  border-radius: 2rem !important;
  color: #ffffff !important;
  background: #188879 !important;
  transition: 200ms transform ease-out;
}

.whitepaper-button:hover {
  background: #02fedc !important;
  transition: 200ms transform ease-in;
  transform: scale(1.05);
  color: #000000 !important;
}

.buttons {
  background-color: transparent;
  border-radius: 2rem;
  border-color: #188879;
  color: #188879 !important;
  width: 50%;
  padding: 0.2rem;
}

.buttons:hover {
  background-color: #188879;
  color: rgb(255, 255, 255) !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* .title {
  font-family: 'minstrels';
} */

.landing__section {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  /* background: radial-gradient(circle at 100%, #02fedc, rgb(232, 135, 39) 50%, #ffffff 75%, rgb(255, 168, 142) 75%); */
  /* background: radial-gradient(circle at 100%, rgb(0, 238, 255), rgb(0, 238, 255) 50%, #ffffff 75%, rgb(0, 238, 255) 75%); */
  /* background-color: #188879; */
  height: 89vh;
  font-size: 3rem;
  text-shadow: 2px 2px 8px #3d3d3d;
}

/* promo - Homepage */

.promo {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0.5rem;
}

.promo__headerText {
  font-size: 400%;
}

.promo__image {
  width: 15rem;
  border-radius: 0.5rem;
}

.hide-it {
  background-color: #000000;
}

.socials {
  margin-top: 1rem;
}

.header {
  background-color: rgba(59, 59, 59, 0.9);
  display: flex;
  justify-content: space-around;
  align-items: center;

}

.App-logo {
  border: 1rem solid #02fedc;
  border-radius: 2rem;
  width: 100%;
}

.App-icon {
  height: 2.3rem;
  margin-top: -0.2rem;
  /* border: 0.2em solid #02fedc;
  border-radius: 0.2rem;
  background-color: #188879; */
}

.newRelease__plateformIcon {
  width: 3rem;
  margin: 0.2rem;
  transition: 200ms transform ease-out;
}

.newRelease__plateformIcon:hover {
  transition: 200ms transform ease-in;
  transform: scale(1.10);
  cursor: pointer;
}

.sectionHeader {
  text-shadow: 2px 2px 10px #3d3d3d;
}

.subcribeModal {
  /* margin-top: 9rem; */
}

.subscribeModal__body {
  background-color: #e0e1dd;
}

.modal-content {
  height: 100%;
  border: 0;
  @include border-radius(0);
}

/* works */
.works {
  min-height: 50vh;
}

.dataSlab__imageDesktop {
  max-height: 16rem;
  border-radius: 0.5rem;
  object-fit: contain;
}

.dataSlab__imageMobile {
  max-height: 20rem;
  border-radius: 0.5rem;
  object-fit: contain;
}

/* Blog */
.blog {
  height: 89vh;
  background-color: #188879;
}

/* Events */
.events {
  height: 89vh;
  background-color: #188879;
}

/* My Story */
.myStory {
  height: 89vh;
  background-color: #188879;
}

.promo__video {
  border-radius: 1rem;
  overflow: hidden;
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.promo__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Socials */
.socials {
  height: 89vh;
  background-color: #188879;
}

/* contact */
.contact {
  height: 89vh;
  background-color: #188879;
}

/* footer component */
footer {
  background-color: rgba(59, 59, 59, 0.9);

  /* background-image: linear-gradient(to top, rgba(59, 59, 59, 0.5) 85%, rgba(1, 100, 86, 0.5) 99%); */

}

.footer__links {
  font-size: 0.8rem !important;
  margin-right: 0 !important;
}

.fa-fw {
  color: #e0e1dd;
}

.fa-fb {
  color: #000000;
}

.fa-medium {
  font-size: 1.7rem;
}

.facebook {
  transition: 200ms transform ease-out;
}

.facebook:hover {
  color: #4267B2;
  transition: 200ms transform ease-in;
  transform: scale(1.10);
  cursor: pointer;
}

.x {
  transition: 200ms transform ease-out;
}

.x:hover {
  color: #ffffff;
  transition: 200ms transform ease-in;
  transform: scale(1.10);
  cursor: pointer;
}

.instagram {
  transition: 200ms transform ease-out;
}

.instagram:hover {
  color: #F77737;
  transition: 200ms transform ease-in;
  transform: scale(1.10);
  cursor: pointer;
}

.youtube {
  transition: 200ms transform ease-out;
}

.youtube:hover {
  color: #FF0000;
  transition: 200ms transform ease-in;
  transform: scale(1.10);
  cursor: pointer;
}

.tiktok {
  transition: 200ms transform ease-out;
}

.tiktok:hover {
  color: #EE1D52;
  transition: 200ms transform ease-in;
  transform: scale(1.10);
  cursor: pointer;
}

.goodreads {
  transition: 200ms transform ease-out;
}

.goodreads:hover {
  color: #e9e5cd;
  transition: 200ms transform ease-in;
  transform: scale(1.10);
  cursor: pointer;
}

.telegram {
  transition: 200ms transform ease-out;
}

.telegram:hover {
  color: #0088CC;
  transition: 200ms transform ease-in;
  transform: scale(1.10);
  cursor: pointer;
}

/* error page */
.errorPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
}



/* media queries */
@media screen and (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

@media screen and (max-width: 1000px) {
  .promo__headerText {
    font-size: 300%;
  }

  .promo__image {
    width: 9rem;
  }

  .dataSlab__image {
    max-height: 10rem;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

}


@media screen and (min-width: 771px) and (max-width: 1000px) {}

@media screen and (min-width: 577px) and (max-width: 770px) {}

@media screen and (max-width: 340px) {}